import {
  Protect as ClerkProtect,
  OrganizationList,
  SignIn,
  SignInButton,
  SignUpButton,
  SignedIn,
  SignedOut,
  UserButton,
} from '@clerk/clerk-react';
import { useEffect, useRef } from 'react';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="container mx-auto max-w-7xl gap-x-6 lg:px-8">
      {children}
    </div>
  );
};

const Protect = ({
  children,
  authorized = true,
}: {
  children: React.ReactNode;
}) => {
  return authorized ? (
    <div>
      <SignedIn>
        <ClerkProtect
          permission="org:task:read"
          fallback={
            <Wrapper>
              <div className="flex items-center justify-center h-auto">
                <OrganizationList hidePersonal={true} />
              </div>
            </Wrapper>
          }
        >
          {children}
        </ClerkProtect>
      </SignedIn>
      <SignedOut>
        <div className="flex items-center justify-center h-auto min-h-screen">
          {/* <RefreshOnce /> */}
          <SignIn afterSignInUrl={window.location.href} />
        </div>
      </SignedOut>
    </div>
  ) : (
    <Wrapper>{children}</Wrapper>
  );
};

export default Protect;

import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from 'relay-runtime';

import { post } from './utils/request';

const fetchFn: FetchFunction = async (request, variables) => {
  if (request.text === null) {
    throw new Error('Request text is null');
  }
  const resp = await post(request.text, variables);
  return resp;
};

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchFn),
    store: new Store(new RecordSource(), {
      // This property tells Relay to not immediately clear its cache when the user
      // navigates around the app. Relay will hold onto the specified number of
      // query results, allowing the user to return to recently visited pages
      // and reusing cached data if its available/fresh.
      gcReleaseBufferSize: 10,
    }),
  });
}

export const RelayEnvironment = createRelayEnvironment();

import Protect from '@/Protect';
import {
  Outlet,
  createLazyFileRoute,
  useLoaderData,
} from '@tanstack/react-router';

export default function AppContent({ sidebarContent, children, right }) {
  return sidebarContent ? (
    <>
      <main className={'lg:pl-72'}>
        <div className={right ? 'xl:pr-96' : 'xl:pl-96'}>
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
            <Protect>{children}</Protect>
          </div>
        </div>
      </main>
      <aside
        className={
          right
            ? 'fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-l border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block'
            : 'fixed inset-y-0 hidden px-4 py-6 overflow-y-auto border-r border-gray-200 left-72 w-96 sm:px-6 lg:px-8 xl:block'
        }
      >
        {sidebarContent}
      </aside>
    </>
  ) : (
    <main className="py-10 lg:pl-72">
      <div className="px-4 sm:px-6 lg:px-8">
        <Protect>{children}</Protect>
      </div>
    </main>
  );
}

// <main className="lg:pl-72">
//   <div className="xl:pr-96">
//     <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">{/* Main area */}</div>
//   </div>
// </main>

// <aside className="fixed inset-y-0 right-0 hidden px-4 py-6 overflow-y-auto border-l border-gray-200 w-96 sm:px-6 lg:px-8 xl:block">
//   {/* Secondary column (hidden on smaller screens) */}
// </aside>

// <main className="lg:pl-72">
//   <div className="xl:pl-96">
//     <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">{/* Main area */}</div>
//   </div>
// </main>

// <aside className="fixed inset-y-0 hidden px-4 py-6 overflow-y-auto border-r border-gray-200 left-72 w-96 sm:px-6 lg:px-8 xl:block">
//   {/* Secondary column (hidden on smaller screens) */}
// </aside>

import { RelayEnvironment } from '@/RelayEnvironment';
import { createFileRoute } from '@tanstack/react-router';
import { loadQuery } from 'react-relay';

// import node, { routeAppQuery } from './__generated__/routeAppQuery.graphql';
import node, {
  routeAppTeamsQuery,
} from './__generated__/routeAppTeamsQuery.graphql';

export const Route = createFileRoute('/app/nav')({
  async loader() {
    const rootQueryRefernce = loadQuery<routeAppTeamsQuery>(
      RelayEnvironment,
      node,
      { user_id: localStorage.getItem('user_id') as string },
      // By default Relay will cache the data indefinitely. This policy makes
      // Relay instantly show the results from the cache, but refetches in the
      // background on every page visit.
      { fetchPolicy: 'store-and-network' }
    );
    // console.log({ rootQueryRefernce });
    return rootQueryRefernce;
  },
});

import AppContent from '@/components/AppContent';
import DownloadReportSection from '@/components/DownloadReportSection';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/app/nav/report')({
  component: () => (
    <AppContent>
      <DownloadReportSection />
    </AppContent>
  ),
});

import { RelayEnvironment } from '@/RelayEnvironment';
import { useAuth, useSession, useUser } from '@clerk/clerk-react';
import { useEffect } from 'react';
import { RelayEnvironmentProvider } from 'react-relay/hooks';

// import { useIsMounted, useUnmount } from 'usehooks-ts';

export const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const { getToken, isSignedIn } = useAuth();
  const { session } = useSession();
  const { user } = useUser();
  // const isMounted = useIsMounted();

  useEffect(() => {
    const updateToken = async () => {
      // console.log('token updated');
      if (isSignedIn) {
        const token = await getToken({ template: 'hasura' });
        const firebaseToken = await getToken({
          template: 'integration_firebase',
        });
        localStorage.setItem('authToken', token || '');
        localStorage.setItem('firebaseToken', firebaseToken || '');
        localStorage.setItem('org_id', session?.lastActiveOrganizationId || '');
        localStorage.setItem('user', JSON.stringify(user || ''));
        localStorage.setItem('user_id', user?.id || '');
        localStorage.setItem('session', JSON.stringify(session || ''));
      }
    };

    const intervalId = setInterval(updateToken, 50); // Set interval to 1 second

    return () => {
      clearInterval(intervalId); // Clear interval on cleanup
    };
  }, [getToken, isSignedIn, session?.lastActiveOrganizationId, user, session]);

  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
};

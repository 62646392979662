import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
  currentDelay: number;
}

const customAxiosApi: AxiosInstance = axios.create({});

const globalConfig: RetryConfig = {
  retry: 7,
  retryDelay: 250,
  currentDelay: 0,
};

const retryRequest = (responseOrError: AxiosResponse | AxiosError) => {
  const { config } = responseOrError;

  if (!config || !config.retry) {
    return Promise.reject(responseOrError);
  }
  config.retry -= 1;
  config.currentDelay += config.retryDelay;
  const delayRetryRequest = new Promise<void>((resolve) => {
    setTimeout(() => {
      console.log(
        'retry the request',
        config.url,
        config.currentDelay,
        config.headers.Authorization,
        localStorage.getItem('authToken')
      );
      resolve();
    }, config.currentDelay || 1000);
  });
  const token = localStorage.getItem('authToken');
  return delayRetryRequest.then(() =>
    customAxiosApi({
      ...config,
      headers: {
        ...config.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    })
  );
};

customAxiosApi.interceptors.response.use(
  (response) => {
    // console.log({ response });
    if (response.data.errors) {
      console.log('is graphql error');
      const customError = new Error(response.data.errors[0].message);
      response.original_status = response.status;
      response.status = response.data.errors[0].extensions.code;
      // add some properties to make it look like a regular axios error
      customError.response = response;
      customError.request = response.request;
      customError.config = response.config;
      customError.isAxiosError = true; // or not
      return retryRequest(response);
    }
    return response;
  },
  (error) => {
    // Do something with response error
    return retryRequest(error);
  }
);

const RELAY_ENDPOINT = import.meta.env.VITE_RELAY_ENDPOINT;

export const post = async (query: string, variables: Record<string, any>) => {
  const token = localStorage.getItem('authToken');
  const { connections, ...variablesWithoutConnections } = variables;

  // console.log({ connections });

  const response = await customAxiosApi.post(
    RELAY_ENDPOINT,
    { query, variables: { ...variablesWithoutConnections } },
    {
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...globalConfig,
    }
  );
  // console.log({ query, variables, data: JSON.stringify(response.data) });

  function transformInputObject(obj) {
    if (typeof obj !== 'object' || obj === null) return obj;

    if (Array.isArray(obj)) {
      return obj.map((item) => transformInputObject(item));
    }

    if (obj && obj.returning) {
      if (!Array.isArray(obj.returning)) {
        obj.returning = [obj.returning];
      }

      obj.returning = obj.returning.map((item) => {
        if (typeof item === 'object') {
          Object.keys(item).forEach((key) => {
            if (
              key.endsWith('connection') &&
              item[key] &&
              item[key].edges &&
              item[key].edges.length === 0
            ) {
              item[key].edges = [{ node: { ...item } }];
              delete item[key].edges[0].node[key];
            }
          });
        }
        return item;
      });
    }

    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        obj[key] = transformInputObject(obj[key]);
      }
    });

    return obj;
  }

  const modified = transformInputObject(response.data);

  // console.log({ modified });

  return modified;
};

export const get = async (url: string, config: AxiosRequestConfig) => {
  const token = localStorage.getItem('authToken');
  const response = await customAxiosApi.get(url, {
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...globalConfig,
  });
  return response.data;
};

import { Suspense } from '@/components/Suspense';
import { Toaster } from '@/components/ui/toaster';
import {
  Outlet,
  ScrollRestoration,
  createRootRoute,
} from '@tanstack/react-router';
import { lazy } from 'react';

const TanStackRouterDevtools =
  import.meta.env.MODE === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

export const Route = createRootRoute({
  component: () => (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools position="bottom-right" />
      </Suspense>
      <Toaster />
    </>
  ),
  // loader: async () => {
  //   await new Promise((resolve) => setTimeout(resolve, 1000));
  //   return { message: 'Hello, world!' };
  // },
});

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { json2csv } from 'json-2-csv';
import { Calendar as CalendarIcon } from 'lucide-react';
import { flatten } from 'ramda';
import React, { useCallback, useEffect, useState, useTransition } from 'react';
import { graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';

import type { DownloadReportSectionQuery } from './__generated__/DownloadReportSectionQuery.graphql';

const DailyTasksQuery = graphql`
  query DownloadReportSectionQuery(
    $user_id: String!
    $start_date: timestamptz
    $end_date: timestamptz
  ) {
    user_organization_connection(where: { user_id: { _eq: $user_id } }) {
      edges {
        node {
          organization {
            workspaces_connection {
              edges {
                node {
                  workspace_members_connection(
                    where: { user_id: { _eq: $user_id } }
                  ) {
                    edges {
                      node {
                        workspace {
                          kanban_boards_connection(
                            where: { block_id: { _is_null: true } }
                          ) {
                            edges {
                              node {
                                tasks_connection(
                                  order_by: { updated_at: desc }
                                  where: {
                                    status: { _eq: "Completed" }
                                    is_deleted: { _eq: false }
                                    updated_at: {
                                      _gte: $start_date
                                      _lte: $end_date
                                    }
                                  }
                                ) {
                                  __id
                                  edges {
                                    node {
                                      kanban_board {
                                        workspace {
                                          # id
                                          name
                                          organization {
                                            name
                                            # id
                                          }
                                        }
                                        name
                                        user {
                                          first_name
                                          last_name
                                        }
                                      }
                                      # next_run
                                      # schedule_expression
                                      # template_id
                                      # template_applied_at
                                      # last_applied_template_id
                                      # id
                                      # depth
                                      # index
                                      # author_id
                                      # parent_task_id
                                      # kanban_board_id
                                      name
                                      description
                                      updated_at
                                      status
                                      # priority
                                      # due_date
                                      # is_deleted
                                      # created_at
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const DatePicker = ({ date, setDate, label }) => {
  return (
    <div>
      <label className="block mb-1 text-sm font-medium text-indego-700">
        {label}
      </label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={'outline'}
            className={cn(
              'w-[200px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            {date ? format(date, 'PPP') : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const DownloadReportSection: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [queryReference, loadQuery] =
    useQueryLoader<DownloadReportSectionQuery>(DailyTasksQuery);
  const [isPending, startTransition] = useTransition();
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    setIsDataFetched(false);
  }, [startDate, endDate]);

  const handleDownload = useCallback(() => {
    if (!startDate || !endDate) {
      alert('Please select both start and end dates');
      return;
    }

    const queryParams = {
      user_id: localStorage.getItem('user_id') as string,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    };

    startTransition(() => {
      loadQuery(queryParams, { fetchPolicy: 'network-only' });
      setIsDataFetched(true);
    });
  }, [loadQuery, startDate, endDate]);

  const DownloadButton: React.FC = () => {
    const data = usePreloadedQuery<DownloadReportSectionQuery>(
      DailyTasksQuery,
      queryReference!
    );

    const exportData = useCallback(async () => {
      const flattenedData = flatten(
        data.user_organization_connection.edges[0].node.organization.workspaces_connection.edges[0].node.workspace_members_connection.edges.reduce(
          (acc, e) => {
            return [
              ...acc,
              e.node.workspace.kanban_boards_connection.edges.map((e) => {
                return e.node.tasks_connection.edges.map((e) => {
                  return e.node;
                });
              }),
            ];
          },
          []
        )
      );

      const csv = await json2csv(flattenedData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'daily_tasks.csv');
    }, [data]);

    return (
      <Button className="bg-indigo-600" onClick={exportData}>
        Download CSV
      </Button>
    );
  };

  return (
    <div className="p-4 mb-4 bg-gray-100 rounded-md">
      <h3 className="mb-2 text-lg font-semibold">Download Report</h3>
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4">
          <DatePicker
            date={startDate}
            setDate={setStartDate}
            label="Start Date"
          />
          <DatePicker date={endDate} setDate={setEndDate} label="End Date" />
        </div>
        <div className="flex items-center space-x-4">
          <Button
            className="text-white bg-indigo-600"
            onClick={handleDownload}
            disabled={isPending}
          >
            {isPending ? 'Loading...' : 'Fetch Data'}
          </Button>
          {queryReference && isDataFetched && <DownloadButton />}
        </div>
      </div>
    </div>
  );
};

export default DownloadReportSection;

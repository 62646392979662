import Header from '@/Header';
import Loading from '@/components/Loading';
import NotFoundComponent from '@/components/NotFound';
import { Suspense } from '@/components/Suspense';
import { TokenProvider } from '@/utils/TokenProvider';
import { get } from '@/utils/request';
import {
  ClerkLoaded,
  ClerkProvider,
  MultisessionAppSupport,
} from '@clerk/clerk-react';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { StrictMode, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import PullToRefresh from 'react-simple-pull-to-refresh';
// reload the page if there is a new update
import { registerSW } from 'virtual:pwa-register';

import './index.css';
// Import the generated route tree
import { routeTree } from './routeTree.gen';

const ReactQueryDevtools =
  import.meta.env.MODE === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        import('@tanstack/react-query-devtools').then((d) => ({
          default: d.ReactQueryDevtools,
        }))
      );

registerSW({ immediate: true });

// Set up a Router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultNotFoundComponent: () => (
    <>
      <Header />
      <NotFoundComponent />
    </>
  ),
  defaultErrorComponent: ({ error }) => {
    throw error;
  },
  // defaultStaleTime: 10_000,
  // defaultStaleTime: Infinity,
});

const queryClient = new QueryClient();

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
const MODE = import.meta.env.MODE;
const PROD = import.meta.env.PROD;

console.log({ PUBLISHABLE_KEY, MODE, PROD });

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}

// Render the app
const rootElement = document.getElementById('root');
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      {/* <PullToRefresh onRefresh={() => window.location.reload()}> */}
      <Suspense>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
          <ClerkLoaded>
            <TokenProvider>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<p>loading...</p>}>
                  <ReactQueryDevtools />
                </Suspense>
                <RouterProvider router={router} />
              </QueryClientProvider>
            </TokenProvider>
          </ClerkLoaded>
        </ClerkProvider>
      </Suspense>
      {/* </PullToRefresh> */}
    </StrictMode>
  );
}
